
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  
} from "reactstrap";
import config from "config";
import LanguageSwitcher from "components/LanguageSwitcher";
import {connect} from 'react-redux';
import {switchLanguage} from 'actions/Setting';
import languageData from "components/LanguageSwitcher/data";
import IntlMessages from "lngProvider/IntlMessages";
import useWindowDimensions from "./useWindowDimensions";

function DetailActivityNavbar({activity,locale,switchLanguage,activityHtmlList}) {
  const getInstitutionLogo = (insName)=>{

    if(insName==="ATAM")
     return config.apiUrl + config.instImageUrlATAM;
    else if(insName==="AKMB")
      return config.apiUrl + config.instImageUrlAKMB;
    else if(insName==="TDK")
      return config.apiUrl + config.instImageUrlTDK;
    else if(insName==="TTK")
       return config.apiUrl + config.instImageUrlTTK;
    else
       return ''

}


  ////console.log("props locale ", locale )

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [logoURL, setLogoUrl] = React.useState(getInstitutionLogo(activity.institution));

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };


  React.useEffect(() => {

    
    if(activity)
    setLogoUrl(getInstitutionLogo(activity.institution));


    const updateNavbarColor = () => {
      if ( 
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };

  });

////console.log("gelen url ,", getInstitutionLogo('AKMB'))

const { height, width } = useWindowDimensions();
////console.log("widthhhh ",width)
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
      justified
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/index"
         //   target="_blank"
            title="BILSIS"
            tag={Link}
            style={{textTransform:'none'}}
          >
      <img 
        className="img-responsive mr-2"
         src={logoURL}
         alt="Etkinlik Kurumu"
         style={ width>440 ?{width:'50px'}: {width:'30px'}}
    //    onError={(e)=>{e.target.onerror = null; e.target.src=config.apiUrl + config.defaultProfileImageUrl }}
     /> 
         
    {width>440 ?
           <IntlMessages id={"institution."+activity.institution}/> :  activity.institution }

          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>

                        
          {/* <DropdownItem href="#webAttachment"  >
                   <IntlMessages id="activity.files"/>
             </DropdownItem>

            <DropdownItem  href="#comittee">
                   <IntlMessages id="committees.title"/>
              </DropdownItem> */}


            
<NavItem>
              <NavLink href="#comittee"    style={{margin: "27px 3px"}}>
              <IntlMessages id="committees.title"/>
              </NavLink>
            </NavItem> 
            <NavItem>
              <NavLink href="#webAttachment" style={{margin: "27px 3px"}}>
              <IntlMessages id="activity.files"/>
              </NavLink>
            </NavItem> 
   
{
  activity.status === 0 && new Date(activity.deadlineAbstract) >=  new Date() ?
            <NavItem>
              <NavLink
                href={ config.loginPageUrl + "bilsis/sendSubmission?a=" + activity.id}
                target="_blank"
                style={{lineHeight:'3.6em'}}
              >
               <IntlMessages id="button.submission.send"/>
              </NavLink>
            </NavItem> : null }


           
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  aria-haspopup={true} 
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuDetailLink"
                  style={{lineHeight:'3.6em'}}
                  nav
               //   onClick={e => e.preventDefault()}
                > <IntlMessages id="header.shortcuts"/>
                </DropdownToggle> 
                <DropdownMenu aria-labelledby="navbarDropdownMenuDetailLink">


  
            <DropdownItem href="#webAttachment"  >
                   <IntlMessages id="activity.files"/>
             </DropdownItem>

            <DropdownItem  href="#comittee">
                   <IntlMessages id="committees.title"/>
              </DropdownItem>

                  {
                    activityHtmlList.map(item=>
                      <DropdownItem
                      href={"#activityHtml"+item.id}
                  //    onClick={e => e.preventDefault()}
                    >
                      <IntlMessages id={"attachmentType."+item.title}/>
                    </DropdownItem>
                      )
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  style={{margin: "25px 3px"}}
                  aria-haspopup={true}
                  caret  
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownLocale"
                  nav
                //  style={{lineHeight:'4.6em'}}
                  onClick={e => e.preventDefault()}
                >
                   <i style={{marginBottom: "-7px"}}  className={`flag flag-24 flag-${locale.icon}`}/> 
                   <p className="ml-2">{locale.locale}</p>
                   {/* Dil */}
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownLocale">

                                                        {languageData.map((language, index) => 
                                                             <DropdownItem
                                                             key={index}
                                                             href="#"
                                                             onClick={
                                                               (e)=>{
                                                                e.preventDefault();
                                                                localStorage.setItem("language",JSON.stringify(language));
                                                                switchLanguage(language);
                                                               }
                                                              }
                                                           >
                                                             <div className="d-flex align-items-center" style={{display:'flex!important'}}>
                                                              <i className={`flag flag-24 flag-${language.icon}`}/>
                                                              <h5 className="mb-0 ml-2">{language.name}</h5>
                                                            </div>
                                                           </DropdownItem>

                                                             )}


              
                </DropdownMenu>
              </UncontrolledDropdown>
      
      

            
    <NavItem>
<NavLink
                data-placement="bottom"
                href="https://yuzuncuyil.gov.tr"
                target="_blank"
                title="Türkiye Cumhuriyeti 100. Yılı"
              >
                <img alt="yy" src={require("assets/img/100.png")} style={{width:'80px', height:40}} />
              </NavLink>
            </NavItem>
{/* 
                  <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.ktb.gov.tr"
                target="_blank"
                title="T.C. Kültür ve Turizm Bakanlığı"
              >
                <img alt="ktb" src={require("assets/img/logo/ktb_logo.png")} style={width>992 ?{width:'40px'} : { width:'20px'}} />
                <p className="d-lg-none ml-2">T.C. Kültür ve Turizm Bakanlığı</p>

              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.ayk.gov.tr"
                target="_blank"
                title="T.C. Atatürk Kültür, Dil ve Tarih Yüksek Kurumu"
              >
                <img alt="ayk" src={require("assets/img/logo/ayk_logo.png")} style={width>992 ?{width:'40px'} : { width:'20px'}}  />
                <p className="d-lg-none ml-2">Atatürk Kültür, Dil ve Tarih Yüksek Kurumu</p>

              </NavLink>
            </NavItem> 
            
          </Nav>
        </Collapse>
        {/* <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={props.switchLanguage}  handleRequestClose={handleRequestClose}/>
                </DropdownMenu> */}
      </Container>
    </Navbar>
  );
}
const mapStateToProps = ({settings}) => {
  const {locale } = settings;
  return { locale }
};

export default connect(mapStateToProps, {switchLanguage})(DetailActivityNavbar);
//export default DetailActivityNavbar;
